.setup_input {
    p {
        margin-bottom: 14px;
        margin-top: 0px;
    }
    .reward_data {
        height: 32px;
        border-radius: 4px;
        background-color: $FORM_ELEMENT_BG_COLOR;
        color: $BASE_DARK_BLACK_COLOR;
        font-size: 13px;
        border: none;
        font-family: "Lato";

        &.weight {
            margin-bottom: 0;
            color: $BASE_BLACK_COLOR;
        }
    }

    .last_weight {
        display: block;
        color: #7b7b7b;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 4px;
    }

    .has-error {
        border: 1px solid red;
    }

    .has-error_area {
        border: 1px solid red;
    }

    .line_divider {
        margin-top: 10px;
    }
    .input_group {
        display: flex;
        gap: 10px;

        .input_item {
            flex: 1;
        }
    }

    .blank {
        width: 150px;
        height: 120px;
        background-color: $SECONDARY_BG_COLOR;
        border-radius: 11.5px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .image_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 120px;
        background-color: $SECONDARY_BG_COLOR;
        border-radius: 11.5px;
        overflow: hidden;

        img {
            height: 100%;
        }
    }
    .ant-select-open {
        .ant-select-selection-item {
            color: black;
        }
    }
    .select_modal {
        height: 34px;
        width: 100%;
        // margin-right: 100px;
        .ant-select-selector {
            align-items: center;
            min-height: 32px;
            border: none;
            background-color: $FORM_ELEMENT_BG_COLOR;
            font-weight: normal;
            font-size: 13px;
            font-family: "Lato";
            border-radius: 4px;
        }
    }
}

.two_rewards_line {
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    gap: 12px;

    .date_wrapper {
        flex: 1;
    }
    .start_date {
        height: 40px;
        min-width: 175px;
        background-color: $FORM_ELEMENT_BG_COLOR;
        border: none;
        border-radius: 4px;
        .ant-picker-input {
            .ant-picker-clear {
                svg {
                    background-color: $MAIN_BG_COLOR;
                    width: 15px;
                    height: 15px;
                    color: $INPUT_MAIN_COLOR;
                }
            }
            svg {
                background-color: $MAIN_BG_COLOR;
                width: 15px;
                height: 15px;
                color: $INPUT_MAIN_COLOR;
            }
        }
    }
    .has-error_area {
        border: 1px solid red;
    }
}

.reward_error_wrapper {
    margin-top: -15px;
    margin-bottom: 20px;
}
