.rewards_filter_layout {
    width: 100%;
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .ant-select-open {
        .ant-select-selection-item {
            color: black;
        }
    }

    .availability_by,
    .sort_by {
        height: 40px;
        width: 105px;
        margin-right: 8px;

        .ant-select-selector {
            height: 40px;
            align-items: center;
            border: none;
            background-color: $FORM_ELEMENT_BG_COLOR;
            font-weight: normal;
            font-size: 13px;
            font-family: "Lato";
            border-radius: 4px;
            color: black;

            .ant-select-selection-item {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .points_filter {
        min-width: 160px;
        max-width: 160px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid transparent;
        background: $FORM_ELEMENT_BG_COLOR;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 12px;
        padding-right: 10px;
        margin-right: 10px;

        &:hover {
            cursor: pointer;
        }

        .points_placeholder {
            margin: 0;
            color: rgba(0, 0, 0, 0.25);
            font-weight: normal;
            font-size: 13px;
            font-family: "Lato";
        }

        .points_text{
            font-weight: normal;
            font-size: 13px;
            font-family: "Lato";
            border-radius: 4px;
            color: black;
        }

        svg {
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.25);
        }
    }

    .points_error{
        border:1px solid $INPUT_ERROR;
    }

    .points_filter_wrapper {
        -webkit-box-shadow: 0px 10px 23px -6px rgba(0, 0, 0, 0.46);
        -moz-box-shadow: 0px 10px 23px -6px rgba(0, 0, 0, 0.46);
        box-shadow: 0px 10px 23px -6px rgba(0, 0, 0, 0.46);
        padding: 10px 18px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: absolute;
        background-color: $BASE_WHITE_COLOR;
        top: 54px;
        left: 225px;
        z-index: 10;

        .filter_fields {
            display: flex;
            align-items: center;
            gap: 10px;

            .point_desc {
                color: $BASE_BLACK_COLOR;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 195%;
            }

            .points_input {
                display: flex;
                width: 64px;
                margin-left: auto;
                border-radius: 4px;
                background-color: $FORM_ELEMENT_BG_COLOR;
                border: 1px solid transparent;
                font-weight: normal;
                font-size: 13px;
                color: $INPUT_MAIN_COLOR;
                font-family: "Lato";
            }

            .points_text{
              font-weight: normal;
              font-size: 13px;
              font-family: "Lato";
              border-radius: 4px;
              color: black;
            }

            .points_error{
                border:1px solid $INPUT_ERROR;
            }
        }
    }

    .start_date {
        height: 40px;
        min-width: 120px;
        max-width: 120px;
        margin-right: 8px;
        background-color: $FORM_ELEMENT_BG_COLOR;
        border: none;
        border-radius: 4px;
        .ant-picker-input {
            .ant-picker-clear {
                svg {
                    background-color: $FORM_ELEMENT_BG_COLOR;
                    width: 15px;
                    height: 15px;
                    color: $INPUT_MAIN_COLOR;
                }
            }
            svg {
                background-color: $FORM_ELEMENT_BG_COLOR;
                width: 15px;
                height: 15px;
                color: $INPUT_MAIN_COLOR;
            }
        }
    }

    .apply_filters_btn {
        display: flex;
        align-items: center;
        border: none;
        border-radius: 4px;
        background-color: $BASE_BLACK_COLOR;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: $BASE_WHITE_COLOR;
        margin-right: 10px;

        svg {
            height: 15px;
            margin-left: 6px;
            color: $BASE_WHITE_COLOR;
        }
    }

    .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        color: $BASE_WHITE_COLOR;
    }

    .ant-input-affix-wrapper:hover {
        border-color: transparent;
    }

    .ant-input-affix-wrapper {
        height: 32px;
        width: 134px;
        border-color: transparent;
        background-color: $MAIN_BG_COLOR;
        border-radius: 4px;

        input {
            border-radius: 4px;
            background-color: $FORM_ELEMENT_BG_COLOR;
            border: none;
            font-weight: normal;
            font-size: 13px;
            color: $INPUT_MAIN_COLOR;
            font-family: "Lato";
        }

        .ant-input-suffix {
            svg {
                width: 15px;
                height: 15px;
                color: $INPUT_MAIN_COLOR;
            }
        }
    }

    .search_container {
        width: auto;
        display: flex;
        justify-content: flex-end;
    }

    .availabilities_containers {
        width: auto;
        display: flex;
        margin-bottom: 10px;
    }
}
