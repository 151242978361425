.configuration_wrapper {
  margin-top: 26px;
  display: flex;
    p {
      margin: 0;
    }
    
    .message_title {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

  .configuration_column {
    width: 350px;
    &:nth-child(2) {
      margin-left: 32px;
      margin-right: 32px;
    }
  }

  .deals_configuration_column {
    &:nth-child(2) {
      margin-left: 80px;
      margin-right: 80px;
    }

    &:nth-child(3) {
      max-width: 354px;
      width: 100%;
    }
  }

  .grid_context_wrapper {
    width: 232px;
    margin-top: 12px;
  }

  .card {
    pointer-events: none;
    width: 112px;
    border: 1px solid $DEAL_CARD_BORDER_COLOR;
    background-color: $BASE_WHITE_COLOR;
    border-radius: 8px;
    padding: 16px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Loto';
      font-size: 12px;
      font-weight: 400;

      .weight {
        max-width: 70px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .order_by_radio_group {
    display: flex;
    flex-direction: column;

    .radio_group {
      cursor: pointer;
      margin-top: 12px;
      display: flex;
      align-items: center;

      .radio {
        margin-right: 12px;
      }

      .value {
        font-size: 12px;
        font-weight: 400;
        font-family: 'Lato';
        line-height: 14.4px;
      }

      input[type='radio'] {
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        outline: none;
        border: 1px solid #b8b8b8;
      }

      input[type='radio']::after {
        content: '';
        display: block;
        width: 70%;
        height: 70%;
        margin: 15% auto;
        border-radius: 50%;
      }

      input[type='radio']:checked:after {
        background: $BASE_YELLOW_COLOR;
        border: 1px solid $BASE_YELLOW_COLOR;
        outline: none;
      }

      input[type='radio']:checked {
        border: 1px solid $BASE_YELLOW_COLOR;
      }
    }
  }

  .order_prefix_input {
    margin-top: 12px;
    background-color: $FORM_ELEMENT_BG_COLOR;
    border: none;

    :focus {
      outline: none;
    }
  }

  .configuration_catalog_preview {
    margin-top: 12px;
    width: 80px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 1.82px 9.11px 0px #00000012;

    .body {
      padding: 8px;

      .title {
        width: 70px;
        font-size: 6px;
        font-weight: 700;
        font-family: 'SpeedeeBold';
        line-height: 8px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .time {
        margin-top: 8px;
        display: flex;
        align-items: center;

        .time_icon {
          font-size: 6px;
          margin-bottom: 0;
        }

        .time_value {
          font-size: 6px;
          margin-left: 2px;
          margin-bottom: 0;
          line-height: 8px;
          letter-spacing: -0.15px;
        }
      }
    }
  }
}

.buttons_wrapper {
  margin: 26px 0;
  display: flex;
  align-items: center;
  gap: 4px;

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: $BASE_WHITE_COLOR;
    border: none;
    outline: none;
  }

  .save {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $BASE_WHITE_COLOR;
    background-color: $BASE_YELLOW_COLOR;
    border: none;

    .icon {
      font-size: 16px;
      margin-left: 2px;
    }
  }

  .cancel {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $BASE_BLACK_COLOR;
    background: transparent;
    border: none;
    box-shadow: none;

    &:hover {
      color: $BASE_BLACK_COLOR !important;
    }
  }
}

.configuration_tabs {
  height: auto;
  margin-bottom: 16px;
  padding-right: 10px;

  .ant-tabs-nav-list {
      .ant-tabs-tab {
          padding: 16px 4px;
          font-family: "Lato";
          .ant-tabs-tab-btn {
              padding-left: 4px;
              padding-right: 4px;
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #6b7280;
              font-family: "Lato";
          }
      }
      .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #212121;
              font-family: "Lato";
              text-shadow: inherit;
          }
      }
      .ant-tabs-ink-bar {
          background: black;
          height: 1px;
      }
  }
}
