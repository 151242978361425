.reward_delivery_view_modal {
    .ant-modal-content {
        background: $BASE_WHITE_COLOR;
        box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.154993);
        padding: 40px 40px 32px;
    }
    .modal_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $DIVIDER_BG_COLOR;
        margin-bottom: 34px;

        .name_wrapper {
            width: 100%;
            flex-direction: column;
            justify-content: start;
            align-items: start;

            h3 {
                margin-bottom: 0px;
            }

            .description {
                color: #91929c;
                font-family: Lato;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px;
                margin-top: 5px;
            }
        }
    }
    .close_modal {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        color: $BASE_BLACK_COLOR;
        :hover {
            color: $BASE_LIGHT_GRAY_COLOR;
        }
    }

    .product_container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .product_wrapper {
            flex: 1;

            .info {
                background-color: #f9f9f9;
                border-radius: 4px;
                padding: 8px;
                height: 182px;
                display: flex;
                flex-direction: column;
            }

            .id {
                font-family: Lato;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                margin-bottom: 6px;
            }

            .name {
                font-family: Lato;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                margin: 0px;
                margin-bottom: 6px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                padding-bottom: 2px;
            }

            .product_error {
                color: $INPUT_ERROR;
                font-family: Lato;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .image1 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 140px;
                height: 100px;
                background-color: $SECONDARY_BG_COLOR;
                overflow: hidden;
                border-radius: 11px;
                margin-top: auto;
                align-self: center;
                img {
                    height: 100%;
                }
            }
        }
    }

    .footer {
        padding-top: 40px;
        .btn_wrapper:hover {
            .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
                color: $BASE_WHITE_COLOR;
            }
        }
        .btn_wrapper {
            display: flex;
            gap: 12px;
            padding-top: 18px;
            border-top: 1px solid $DIVIDER_BG_COLOR;
            justify-content: end;
            button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $BASE_BLACK_COLOR;
                color: $BASE_WHITE_COLOR;
                border: none;
                border-radius: 4px;
                font-family: "Lato";
                font-size: 13px;
                min-width: inherit;
                padding: 8px 24px;

                span,
                svg {
                    color: $BASE_WHITE_COLOR;
                    font-size: 13px;
                }
                svg {
                    width: 13px;
                    height: 13px;
                }
            }
            p {
                font-weight: normal;
                font-size: 13px;
                color: $BASE_BLACK_COLOR;
                cursor: pointer;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
