.rewards_wrapper {
    margin-top: 26px;
    .btn_wrapper {
        display: flex;
        margin-top: 15px;
        justify-content: flex-start;
        margin-bottom: 15px;

        .sync_container {
            padding: 16px 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            background-color: $MAIN_BG_COLOR;

            .info_wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 8px;
                margin: 0;
            }

            .reward_count {
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                color: $BASE_BLACK_COLOR;
                margin: 0;
            }

            .count_desc {
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $BASE_DARK_BLACK_COLOR;
                margin-bottom: 0;
            }

            button {
                padding: 8px 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $BASE_YELLOW_COLOR;
                border: none;
                min-width: 75px;
        
                span,
                svg {
                    color: $BASE_WHITE_COLOR;
                    font-size: 13px;
                }
            }
        }

        .subtype_btn {
            display: flex;
            align-items: end;
            justify-content: center;
            margin-left: auto;
        }


        .right_btns {
            margin-left: auto;
            display: flex;
            gap: 10px;
            a {
                text-decoration: none;
            }
        }
    }
}
