//BASE COLOR
//---------------------------------------------------------------------------------------------------
$BASE_BLACK_COLOR: #000000;
$BASE_LIGHT_BLACK_COLOR: #242c40;
$BASE_WHITE_COLOR: #ffffff;
$BASE_DARK_WHITE_COLOR: #f0f0f0;
$BASE_GRAY_COLOR: #8d8d8d;
$BASE_WARM_GRAY_COLOR: rgb(131, 131, 131);
$BASE_DARK_GRAY_COLOR: #626262;
$BASE_LIGHT_GRAY_COLOR: #a6a6a6;
$BASE_LIGHT_RED_COLOR: #d17373;
$BASE_DARK_RED_COLOR: #f5222d;
$BASE_BLUE_COLOR: #4d98c2;
$BASE_YELLOW_COLOR: #ffc200;
$BASE_PINK_COLOR: #f946b7;
$BASE_DARK_BLACK_COLOR: #687992;
$BASE_PURPLE_COLOR: #6100e0;
$BASE_GREEN_COLOR: #7ed321;
$MAIN_BG_COLOR: #f5f5f5;
$SECONDARY_BG_COLOR: #edf0f2;
$INPUT_SECONDARY_BG: #151515;
$INPUT_MAIN_COLOR: #91929c;
$BASE_DARK_BLUE_COLOR: #392f6f;
$MAIN_BORDER_COLOR: #edf0f2;
$FORM_ELEMENT_BG_COLOR: #f4f5f7;
$DIVIDER_BG_COLOR: #efefef;
$OPTION_BG_COLOR: #cad3da;
$MAIN_TEXT_COLOR: #242c40;
$MAP_ICON_COLOR: #b1b9cc;
$DISABLED_BTN_COLOR: #dadada;
$HOVER_COLOR: #f5f5f5;
$ICON_SEARCH_INPUT: #595959;
$INPUT_FOCUSED: #4096ff;
$INPUT_ERROR: #f5222d;
$BORDER_COLOR_PAGINATION_ACTIVE: #1677ff;
$BORDER_COLOR_PAGINATION: #d9d9d9;
$DEAL_CARD_BORDER_COLOR: #CFCFCF;

//---------------------------------------------------------------------------------------------------

//Login Layout CSS
$DIVIDER_COLOR: #323232;
$COPY_RIGHT_COLOR: #5b5b5b;

//BASE CSS
//---------------------------------------------------------------------------------------------------
$Auth_LEFT_WIDTH: 392px;
$NAV_BAR_WIDTH: 262px;
//---------------------------------------------------------------------------------------------------

//Basic components
//---------------------------------------------------------------------------------------------------
//NavBar COLOR
$NAV_BAR_TEXT_COLOR: #838383;
$AVATAR_BG_COLOR: #161616;
$NAV_GROUP_TEXT_COLOR: #424242;
//---------------------------------------------------------------------------------------------------
//Overview COLOR
$TOP_SALES_LIST_FOOD_TITLE_COLOR: #242c40;
//ORDER COLOR
$TABLE_BG_COLOR: #fafafa;
$TABLE_DEVICE_BG_COLOR: #edf0f2;
$TABLE_ACTION_BTN_BG_COLOR: #edf0f2;
$ORDER_MODAL_GROUP_COLOR: #d6d6d6;
$ORDER_MODAL_GROUP_DEVIDER_COLOR: #eeeeee;
$ORDER_MODAL_SUB_DESCRIPTION_COLOR: #a2a2a2;
$ORDER_MODAL_ITEMS_LEFT_BAR_COLOR: #ececec;
$ORDER_MODAL_PAY_WAY_COLOR: #4c5764;
$ORDER_MODAL_CARD_BG_COLOR: #ebeef2;
//Restaurant COLOR
$TOOLTIP_BG_COLOR: #bcbcbc;
//Category COLOR
$CATEGORY_DETAIL_MODAL_SCHEDULE_TIME_COLOR: #878787;
//User Color
$USER_EDIT_HEADER_TEXT_COLOR: #b2b2b2;
$USER_EDIT_PWD_LABEL_COLOR: #a7a7a7;
$USER_DELIVERY_UPDATE_FOODER_COLOR: #c4c4c4;
//OTS Agent Color
$USER_AVATAR_BG_COLOR: #eeeeee;
$USER_DELETE_BTN_BG_COLOR: #999999;
//Ambassadors Color
$MAP_MAKER_OVAL_COLOR: #50e3c2;
$MAP_TOOLTIP_COLOR: #b1b1b1;
$SYNC_MODAL_ERROR_DIVIDER_COLOR: #f54141;

//Order Status
$Waiting_Payment: #6ec1b1;
$Received: #674fa6;
$In_Production: #e8a159;
$Assigned: #a54f78;
$On_the_way: #3a82c3;
$At_the_door: #0c51c9;
$Scheduled: #e53fc2;
$Claimed: #fe1f20;
$Resolving: #202020;
$Delivered: #6ba752;
$Cancelled: #930c0c;
